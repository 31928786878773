import { render, staticRenderFns } from "./BlogPageView.vue?vue&type=template&id=6b68d5ce&"
import script from "./BlogPageView.vue?vue&type=script&lang=js&"
export * from "./BlogPageView.vue?vue&type=script&lang=js&"
import style0 from "./BlogPageView.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports